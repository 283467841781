.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px;
  &:focus {
    outline: 0;
  }
  &:not(.is-open):hover .choices__inner {
    border-color: c(map-get($border-colors, hover));
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.is-disabled {
    .choices__inner,
    .choices__input {
      background-color: c(grey-100);
      cursor: not-allowed;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .choices__item {
      cursor: not-allowed;
    }
    }
  [hidden] {
    display: none !important;
  }
  &[data-type*=select-one] {
    cursor: pointer;
    
    .choices__inner {
      padding-bottom: 7px;
    }
    .choices__input {
      display: block;
      width: 100%;
      padding: 10px;
      border-bottom: 1px solid c(map-get($border-colors, default));
      background-color: c("white");
      margin: 0;
    }
    .choices__button {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
      padding: 0;
      background-size: 8px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -10px;
      margin-right: 25px;
      height: 20px;
      width: 20px;
      border-radius: 10em;
      opacity: .5;
    }
    &:focus,
    &:hover {
      opacity: 1;
    }
    &:focus {
      box-shadow: 0 0 0 2px c(grey-800);
    }
    .choices__item[data-value=''] .choices__button {
      display: none;
    }
    &:after {
      content: '\6b';
      font-family: 'advicefront';
      font-size: rem(18);
      color: c(secondary-light);
      position: absolute;
      right: 11px;
      top: 50%;
      transform: rotate(0deg) translateY(-50%);
      transform-origin: top;
      transition: transform .4s ease-in-out;
      margin-top: 2px;
      pointer-events: none;
    }
    &.is-open:after {
      transform: rotate(-180deg) translateY(-50%);
      // border-color: transparent transparent c(secondary-light);
      // margin-top: -7px;
    }
    &[dir=rtl] {
      &:after {
        left: 11px;
        right: auto;
      }
      .choices__button {
        right: auto;
        left: 0;
        margin-left: 25px;
        margin-right: 0;
      }
    }
  }
  &[data-type*=select-multiple],
  &[data-type*=text] {
    .choices__inner {
      cursor: text;
    }
    .choices__button {
      position: relative;
      display: inline-block;
      margin: 0 -4px 0 8px;
      padding-left: 16px;
      border-left: 1px solid c(secondary-light);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
      background-size: 8px;
      width: 8px;
      line-height: 1;
      opacity: .75;
      border-radius: 0;
      &:focus,
      &:hover {
        opacity: 1;
      }
    }
  }
}
.choices__inner {
  display: flex;
  align-items: center;
  font-size: rem(16);
  width: 100%;
  background-color: c("white");
  padding: 7px 7px 3.75px;
  border: 1px solid c(map-get($border-colors, default));
  border-radius: 2px;
  min-height: 44px;
  overflow: hidden;
}
.is-open .choices__inner {
  border-color: c(map-get($border-colors, focus));
  border-radius: 2px 2px 0 0;
}
.is-focused:not(.is-open) .choices__inner {
  border-color: c(map-get($border-colors, hover));
}
.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2px 2px;
}
.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
  &--dropdown .choices__placeholder{
    display: none !important; 
  }
}
.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
  .choices__item {
    width: 100%;
    padding-right: 24px;
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: c(grey-900);
  }
}
[dir=rtl] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--multiple {
  display: inline;
  
  .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 20px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: c(terciary-light);
    border: 1px solid c(secondary-light);
    color: c("white");
    // word-break: break-all;
    box-sizing: border-box;
    &[data-deletable] {
      padding-right: 5px;
    }
    &.is-highlighted {
      @include media('screen', 'md') {
        background-color: c(terciary-light);
        border: 1px solid c(map-get($border-colors, active));
      }
    }
  }
}
[dir=rtl] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: transparent;
  border: 1px solid c(map-get($border-colors, disabled));
}
.choices__list--dropdown {
  visibility: hidden;
  z-index: 100;
  position: absolute;
  width: 100%;
  background-color: c("white");
  border: 1px solid c(map-get($border-colors, default));
  top: 100%;
  margin-top: $spacing;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  overflow: hidden;
  // word-break: break-all;
  will-change: visibility;
  &.is-active {
    visibility: visible;
  }
  .choices__list {
    position: relative;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
  }
  .choices__item {
    position: relative;
    padding: 10px;
    font-size: rem(14);
    color: c(grey-700);
    &[data-value^="other" i] {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: #{$spacing / 2};
        display: block;
        width: calc(100% - #{$spacing});
        height: 1px;
        background-color: c(grey-500);
      }
    }
  }
}
.is-open .choices__list--dropdown {
  border-color: c(map-get($border-colors, focus));
}
.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: $spacing;
  border-radius: .25rem .25rem 0 0;
}
[dir=rtl] .choices__list--dropdown .choices__item {
  text-align: right;
}
@media (min-width:640px) {
  .choices__list--dropdown {
    .choices__item--selectable {
      padding-right: 100px;
      &:after {
        content: attr(data-select-text);
        font-size: 12px;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  } 
  [dir=rtl] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
    &:after {
      right: auto;
      left: 10px;
    }
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  @include media('screen', 'md') {
    color: c(secondary-light);
    background-color: c(terciary-light);
  }
  
  &:after {
    opacity: .5;
  }
}
.choices__item {
  cursor: default;
}
.choices__item--selectable {
  cursor: pointer;
}
.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: .5;
}
.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid c(map-get($border-colors, default));
  color: c(grey-800);
}
.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus,
.choices__input:focus {
  outline: 0;
}
.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: c("white");
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}
[dir=rtl] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}
.choices__placeholder {
  opacity: .5;
}
// Override
.choices {
  &.type-borderless {
    .choices__inner {
      border: none;
      background-color: transparent;
    }
  }
}
// Error (missing input or wrong value)
.has-error {
  .choices {
    &:after {
      color: c(red-500);
    }
  }
  
  .choices__list--single .choices__item.choices__item--selectable {
    color: c(red-500);
  }
  .choices__inner {
    border-color: c(red-500);
  }
}