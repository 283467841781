.chart {
    height: 200px;
    margin-top: -20px;
}

.chart-color-label {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    margin-right: 1rem;
    background-color: var(--chart-color);
}