///
///  Example
///    <div id="layout-message *is-open*">
///      <div id="layout-message-main">
///        <div id="layout-message-list">...</div>
///        <div id="layout-message-content">
///          <div id="layout-message-header">...</div>
///          <div id="layout-message-view">...</div>
///          <div id="layout-message-input">...</div>
///        </div>
///      </div>
///    </div>
///

#layout {
  &-message {
    overflow: hidden;
    height: calc(100vh - #{$page-header-height});
    margin-bottom: -#{$page-footer-height};
    display: flex;
    flex-direction: column;

    @include media("screen", 'lg') {
      overflow: auto;
    }

    // Message header
    &-header {
      display: flex;
      flex-direction: column;

      @include media('screen', 'lg') {
        flex-direction: row;
        align-items: center;
      }

      &.new-message {
        transform: translateX(calc(100%));

        @include media("screen", 'lg') {
          transform: none;
        }
      }
    }

    &-subject {
      width: 100%;
      flex: 1 0 100%;
      transform: translateX(100%);
      transition: transform .4s ease-in-out;

      @include media('screen', 'lg') {
        flex: 1 1 75%;
        transform: none;
      }
    }

    // Message list + content
    &-main-wrapper {
      overflow-x: hidden;
    }
    
    &-main {
      display: flex;
      height: 100%;
      min-height: 500px;
      transition: transform .4s ease-in-out;
    }

    &-list {
      width: 100%;
      flex: 1 0 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;

      @include media('screen', 'lg') {
        width: 25%;
        flex: 1 1 25%;
        min-width: 250px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1 0 100%;
      height: 100%;

      @include media('screen', 'lg') {
        width: 75%;
        flex: 1 1 75%;
      }
    }

    &-view {
      flex: 1 1 0;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &-window {
      flex: 1 1 0;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    // Options
    &.is-open {
      @include media('screen', '<lg') {
        #layout-message-subject {
          transform: translateX(0);
        }
        #layout-message-main {
          transform: translateX(-100%);
        }
      }
    }
  }
}