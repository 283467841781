.dz {
  &.dz-drag-hover {
    position: relative;
  
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: c(primary-light, .3);
      z-index: 10;
      border: 1px dashed c(secondary-light);
      margin: 0;
    }
  }

  // Main container
  .dz-main {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: c("white", .9);
    z-index: 10;
    border: 1px solid c(secondary-light);
    margin: -1px;
    padding: #{$spacing};
    overflow-x: hidden;
    overflow-y: auto;

    @include media('screen', 'md') {
      padding: #{$spacing * 1.5};
    }

    &.dz-fade-in {
      display: block;
      animation-duration: 1s;
      animation-name: dz-fadeIn;
    }

    &.dz-fade-out {
      display: block;
      animation-duration: 1s;
      animation-name: dz-fadeOut;
    }
  }

  .dz-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .dz-upload-total {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dz-upload-gauge {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid c(secondary-light);
    overflow: hidden;
    transform: rotate(-90deg);

    .dz-upload-progress {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 0;
      height: 100%;
      transition: width .3s ease-out;
      background-color: c(primary-light);
    }
    
    .icon {
      z-index: 1;
      font-size: 18px;
      line-height: 0;
      margin-top: 3px;
      margin-left: -5px;
      transform: rotate(90deg);
    }
  }

  .dz-uploading-text {
    margin-top: #{$spacing};
    font-size: rem(14);

    [data-dz-total-upload] {
      font-weight: map-get($font-weights, medium);
    }
  }

  // This will have all entries generated by Dropzone
  .dz-upload-list {
    display: none; // for now
    margin: 0;
    padding: 0;
    list-style: none;
  }

  // Individual preview
  .dz-preview {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 290px;
    border-radius: 20px;
    border: 1px solid c(grey-500);
    padding: #{($spacing / 2) $spacing};
    margin-top: #{$spacing / 2};

    &:first-child {
      margin-top: #{$spacing};
    }

    &.dz-processing {}

    [data-dz-name] {
      font-size: rem(14);
      color: c(grey-900);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: #{$spacing};
    }

    [data-dz-size] {
      font-size: rem(12);
      color: c(grey-700);
      white-space: nowrap;
      margin-left: #{$spacing};
    }
  }
}

// Animations
@keyframes dz-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes dz-fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}