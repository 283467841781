.node-goal-card {
  .node-goal-card-header {
    line-height: 24px;
    letter-spacing: 0em;
    font-weight: 400;
    text-align: left;
    padding: #{$default-padding} #{$default-padding * 2};

    .node-goal-title {
      font-size: 16px;
      font-weight: 700;
    }

    .node-goal-subtitle {
      font-size: 12px;
      line-height: 18px;
      margin: 0;
    }
    
    .node-goal-subtitle-values {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      margin: 0;
    }
  }

  .node-goal-body {
    padding: #{$default-padding / 2};
    margin: 0 #{$default-margin};

    .gray-box {
      background: $grey-100 !important;

      p {
        font-family: Lato;
        font-size: 14px;
        line-height: 19.6px;
        margin: 4px 0;
      }

      .header-text {
        color: #868D9C;
        margin-bottom: 8px;
      }
    }

    .indent-row {
      padding: 0 8px;
    }
  }
}