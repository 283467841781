.tag {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	min-height: 20px;
	padding: 0 #{$default-padding / 2};
	font-size: rem(12);
	color: $grey-900;
	background-color: $grey-500;
	border-radius: 2px;
	white-space: nowrap;

	&::after {
		display: none; // In case the tag is a link
	}

	&.tag-rounded {
		border-radius: 20px;
		color: $secondary-light-color;
		background-color: $terciary-light-color;
		padding: 0 12px;
	}

	&.tag-rounded-inverted {
		@extend .tag-rounded;
		background-color: transparent;
		border: 1px solid $secondary-light-color;
	}

	.tag-close {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding-left: $default-padding / 2;

		&::after {
			display: none;
		}

		.icon {
			color: $primary-dark-color;
			font-size: rem(10);
			height: 10px;

			&::after {
				font-weight: bold !important;
			}
		}
	}

	// Sizes
	&.tag-big {
		min-height: 24px;
	}

	/*
    Background colours
    Use with .tag
  */

	&.has-bg-grey-light {
		color: $grey-900;
		background-color: $grey-500;
	}

	&.has-bg-green {
		color: $white;
		background-color: $green-500;
	}

	&.has-bg-yellow {
		color: $white;
		background-color: $yellow-500;
	}

	&.has-bg-yellow-light {
		color: $grey-900;
		background-color: $yellow-100;
	}

	&.has-bg-red {
		color: $white;
		background-color: $red-500;
	}

	&.has-bg-red-light {
		color: $grey-900;
		background-color: $red-100;
	}

	&.has-bg-transparent {
		background-color: transparent;
	}

	&.has-bg-primary {
		color: $white;
		background-color: $primary-light-color;
	}

	&.has-bg-grey-dark {
		color: $white;
		background-color: $grey-900;
	}

	/*
    Border colors
    Use with .tag-rounded
  */

	&.has-border-green {
		color: $green-500;
		border: 1px solid $green-500;
	}

	&.has-border-yellow {
		color: $yellow-500;
		border: 1px solid $yellow-500;
	}

	&.has-border-red {
		color: $red-500;
		border: 1px solid $red-500;
	}

	&.has-border-grey {
		color: $grey-900;
		border: 1px solid $grey-700;
	}
}
