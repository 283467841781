
.dropdown {
    &__label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 7px 3.75px;
      background-color: white;
      border: 1px solid c(grey-500);
      cursor: pointer;
      user-select: none;

      .icon{
        transform: rotate(0deg);
        transition: transform .4s  ease-in-out;
      }
      
      // handled from JS
      &.is-open {
        border: 1px solid c(map-get($border-colors, active));

        .icon {
          transform: rotate(-180deg);
          transition: transform .4s  ease-in-out;
        }
      }
    }
  
    &__wrapper {
      width: 100%;
      z-index: 300;
      background-color: white;
      border: 1px solid c(map-get($border-colors, active));
      visibility: hidden;
      pointer-events: none;

      &__item {
        width: 100%;
        height: fit-content;
        list-style: none;

        &__link {
          display: inline-block;
          padding: 10px;
          width: 100%;
          height: 100%;
          color: c(grey-700);
        }
      }
  
      // handled from JS
      &.is-open {
        height: fit-content;
        visibility: visible;
        pointer-events: all;
        transition:  all .2s  ease-in-out;
      }
    }
  
    &__label,
    &__wrapper {
      box-sizing: border-box;
      padding: 10px;
      border-radius: 2px;
    }
}
  
  