#layout {
  &-fact-find {
    .fact-find-form-actions {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 400;
      
      @include media('screen', 'md') {
        left: $page-nav-width;
        width: calc(100% - #{$page-nav-width});
      }
    }

    .form-info{
      font-size: rem(12);
      font-weight: map-get($font-weights, regular);
      font-style: italic;
      line-height: rem(14);
      color: c(grey-700);
      margin: 4px 0;
      display: inline-block;
    }
  }
}
