@mixin loaderFunc($steps) {
  $initial-degrees: (360/$steps) * -1;
  $initial-delay: ($steps)/10 * -1;
  @for $i from 1 to $steps + 1 {
    $degrees-inc: (360/$steps)* $i;
    $delay-inc: -0.1s * $i;
    .loader{
      div{
        &:nth-child(#{$i}){
          transform: rotate(#{$initial-degrees + $degrees-inc}deg);
          animation-delay: #{$initial-delay - $delay-inc};
        }
      }
    }
  }
}

.loader {
  color: c(primary-light);
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  
  div {
  transform-origin: 20px 20px;
  // the animation duration should be the number of steps divided by 10
  animation: loader .8s linear infinite;
  
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 1px;
      left: 19px;
      width: 2px;
      height: 10px;
      border-radius: 20%;
      background: c(primary-light);
    }
  }
}

// number of steps (dashes on the loader)
@include loaderFunc(8);

@keyframes loader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
