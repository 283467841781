.sr-portfolio-list {
    &-header {
        display: flex;
        align-items: center;
        
        .chart-color-label {
            flex-shrink: 0;
        }
    }

    &-item {
        display: flex;
        padding: .66rem 0;
        justify-content: space-between;
    
        &:not(:last-child) {
            border-bottom: solid 1px $grey-500;
        }
    
        &__text {
            font-size: 14px;
            color: $grey-700;
        }
    
        &__value {
            margin-left: .5rem
        }
    }
}

.sr-history {
    &-title {
        font-size: 14px;
        text-transform: uppercase;
        color: $grey-700;
        font-weight: normal;
    }

    &-items {
        align-items: flex-end;
        padding: .5rem .5rem 1.5rem;
        border-top: solid 1px $grey-500;
        border-bottom: solid 1px $grey-500;
    }

    &-item {
        &-title {
            margin: 1rem 0 .5rem;
        }

        &-value {
            font-weight: bold;
        }
    }
}