///
///  Example:
///   <div class="modal micromodal-slide" id="M<id here>" aria-hidden="true">
///     <div class="modal-overlay" tabindex="-1" data-micromodal-close>
///       <section class="modal-container" role="dialog" aria-modal="true" aria-labelledby="MT<id here>">
///         <header class="row middle-xs p-xs-2 bg-xs-grey-100">
///           <div class="col-xs-8">
///             <h1 class="font-xs-18 font-md-22 font-xs-light text-xs-grey-900 m-xs-y-0" id="MT1574681560">
///               Micromodal
///             </h1>
///           </div>
///           <div class="col-xs-4 text-xs-right">
///             <button class="modal-close" aria-label="Close modal" data-micromodal-close></button>
///           </div>
///         </header>
///         <main class="p-xs-2" id="MC1574681560">
///           ...
///         </main>
///         <footer class="row p-md-x-2 p-md-y-1-5 end-md">
///           <div class="col-xs-12 col-md">
///             <button class="btn btn-md-border-secondary-light" data-micromodal-close>Cancel</button>
///           </div>
///           <div class="col-xs-12 col-md">
///             <button class="btn btn-xs-bg-primary-light">Ok</button>
///           </div>
///         </footer>

///       </section>
///     </div>
///   </div>
///

.modal {

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: c("black", .6);
    z-index: map-get($z-indexes, modal);
    overflow-y: auto;
  }

  &-pdf-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    header{
      flex-shrink: 0;
      background: #3E3E3E;
    }

    main{
      flex-grow: 2;
    }

    footer{
      flex-shrink: 0;
    }

    .modal-close {
      &::before {
        font-family: 'advicefront';
        font-size: 18px;
        content: '\63';
        color: white;
      }
    }
  }
  
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    height: 100vh;
    // overflow-y: auto;
    background-color: c("white");

    @include media('screen', 'md') {
      height: auto;
      margin: #{$spacing * 2.5} auto;
    }

    &.singpass {
      @include media('screen', 'md') {
        min-height: 620px;
      }
    }

    header {
      flex-shrink: 0;
    }

    main {
      flex-grow: 1;
    }

    footer {
      flex-shrink: 0;
    }
  }

  &-close {
    &::before {
      font-family: 'advicefront';
      font-size: 18px;
      content: '\63';
    }
  }

}


/// Animation
@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;

  &.is-open {
    display: block;
  }

  &[aria-hidden="false"] {
    .modal-overlay {
      animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
    }
    
    .modal-container {
      animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
    }
  }
  
  &[aria-hidden="true"] {
    .modal-overlay {
      animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
    }
  
    .modal-container {
      animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
    }
  }

  .modal-container,
  .modal-overlay {
    will-change: transform;
  }
}