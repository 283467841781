.accordion {
    border-top: solid 1px $grey-500;

    &.full-border {
        border: solid 1px $grey-500;
        border-radius: 8px;
    }

    &__header {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        padding: 1rem 0;
        border-bottom: solid 1px $grey-500;
        justify-content: space-between;
        
        &__icon {
            margin-left: 1rem;
            transition: transform .3s ease;
        }

        &.is-active &__icon {
            color: c("primary-light");
            transform: rotate(180deg);
        }
    }

    &__body {
        --accordion-body-display: none;
        --accordion-body-position: relative;
        --accordion-body-opacity: 1;
        --accordion-body-visibility: visible;
        --accordion-body-pointer-events: all;
        --accordion-body-transition: none;
        --accordion-body-ovverflow: visible;
        --accordion-body-height: auto; // JS manipulated
        
        display: var(--accordion-body-display);
        position: var(--accordion-body-position);
        padding-left: 1rem;
        border-bottom: solid 1px $grey-500;
        opacity: var(--accordion-body-opacity);
        visibility: var(--accordion-body-visibility);
        pointer-events: var(--accordion-body-pointer-events);
        height: var(--accordion-body-height);
        overflow: var(--accordion-body-overflow);
        transition: var(--accordion-body-transition);

        &.is-active {
            --accordion-body-display: block;
        }

        &.is-rendering {
            --accordion-body-position: absolute;
            --accordion-body-opacity: 0;
            --accordion-body-visibility: hidden;
            --accordion-body-pointer-events: none;
        }

        &.is-animating {
            --accordion-body-transition: height .3s ease;
            --accordion-body-overflow: hidden;
        }
    }
}