// TABLE
.medium-editor-table {
  border-collapse: collapse;
  resize: both;
  table-layout: fixed;
}

.medium-editor-table,
.medium-editor-table td {
  border: 1px solid c(map-get($border-colors, default));
}

