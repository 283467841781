.risk-level {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 74px;
    overflow: visible;
    background-image: url('../../images/risk-level-range.png');
    background-repeat: no-repeat;
    background-size: contain;
  
    .risk-level-value {
      color: #ACAFC2;
      font-size: rem(34);
      letter-spacing: -.05em;
      margin: 0;
      padding: 0;
    }
  
    .risk-level-mark {
      position: absolute;
      width: 80px;
      height: 80px;
      transform: rotate(5deg);
      background-image: url('../../images/risk-level-mark.png');
      background-repeat: no-repeat;
      background-size: contain;
      transition: transform .4s ease-in-out;
    }
    
    &.type-small {
      transform: scale(.6);
      margin: -18px;
    }
  }
  
  .risk-profile-status-icon {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    position: relative;
  
    &.completed {
      border-color: #8BC34A;
      background-color: #DCEDC9;
  
      &::after {
        content: "";
        background-color: #8BC34A;
        position: absolute;
        top: 0;
        bottom: 50%;
        left: 0;
        right: 0;
      }
    }
  
    &.pending {
      border-color: #ECA432;
      background-color: #F9E4C2;
  
      &::after {
        content: "";
        background-color: #ECA432;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        right: 0;
      }
    }
  }

.risk-questionnaire-form {
  max-height: 60%;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.risk-questionnaire-img-container {
  position: relative;
  width: 80%;
  height: auto;
  margin: 0 auto;
  background-color: $white;
  
  @include media('md') {
    width: 400px;
    transition: width .4s, height .4s ease-in-out;
    -webkit-transition: width .4s, height .4s ease-in-out;
    -moz-transition: width .4s, height .4s ease-in-out;
    -o-transition: width .4s, height .4s ease-in-out;

    &:hover {
      .icon-search {
        display: block;
      }
    }
  }

  &.expanded {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 400;
    
    .risk-questionnaire-img {
      transform: rotate(-90deg);
      max-width: 80vh;
      max-height: 80vw;
      
      @include media('md') {
        transform: none;
        max-height: 80%;
        max-width: 80%;
      }
    }

    .icon-error {
      display: block;
      position: absolute;
      top: 20px;
      left: 20px;
      cursor: pointer;

      @include media('md') {
        left: initial;
        right: 20px;
      }
    }
  }

  .risk-questionnaire-img {
    max-width: 90%;
    height: auto;
    cursor: pointer;
    
    @include media('md') {
      max-width: 400px;
      transition: max-width .4s ease-in-out;
      -webkit-transition: max-width .4s ease-in-out;
      -moz-transition: max-width .4s ease-in-out;
      -o-transition: max-width .4s ease-in-out;
    }
  }

  .icon-error {
    display: none;
  }
  
  .icon-search {
    display: block;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

    @include media('md') {
      display: none;
    }
  }
}
  