///
///  Example
///    <div id="layout-page">
///      <div id="layout-navigation">...</div>
///      <div id="layout-main">
///        <div id="layout-header">
///          <div id="layout-header-menu">...</div>
///          <div id="layout-notifications">...</div>
///        </div>
///        <div id="layout-content">...</div>
///        <div id="layout-footer-menu">...</div>
///      </div>
///    </div>
///

#layout {
  &-page {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include media('screen', '<lg') {
      overflow-y: hidden;
    }

    @include media('lg') {
      max-width: $page-nav-width;
      z-index: 2;
      overflow-y: auto;
    }

    // This handles the mobile menu behaviour
    &.is-open {
      @include media('screen', '<md') {
        overflow-y: auto;

        & + #layout-main {
          position: fixed;
          transform: scale(.6) translateX(80vw);
          overflow-y: auto;
          box-shadow: -10px 0 50px c("black",.8);
        }
      }

      @include media('screen', '<lg') {
        overflow-y: auto;

        & + #layout-main {
          position: fixed;
          transform: scale(.8) translateX(#{$page-nav-width + 16px});
          overflow-y: auto;
          box-shadow: -10px 0 25px c("black",.8);
        }
      }
    }
  }

  &-main {
    position: relative;
    width: 100%;
    height: 100%;

    @include media('screen', '<lg') {
      transition: transform .4s ease-in, overflow .0s .4s, box-shadow .4s ease-in, position .4s ease-in;
      // Added to hide the gutters when the personal menu is open
      overflow-x: hidden;
    }
  }

  &-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
  }

  &-header-menu {
    position: relative;
    min-height: $page-header-height;
    z-index: map-get($z-indexes , menu);
  }

  &-notifications {
    position: relative;
    z-index: map-get($z-indexes , notification);
  }

  &-content {
    position: relative;
    padding-top: $page-header-height;
    padding-bottom: $page-footer-height;
    z-index: 0;
    @include media('lg') {
      padding-left: $page-nav-width;
    }
  }

  &-footer-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: $page-footer-height;
    z-index: 1;
  }
}
