.nav-menu {

    .simplebar-content{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }

    .simplebar-content:before, .simplebar-content:after {
    display: none;
    }

}
