.agreements-list {
	margin: 0 -1rem;
	padding-bottom: 166px;
	
	@include media('screen', 'md') {
		padding-bottom: 0;
		margin: 0 -2.5rem;
		// hide scrollbar
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		/* Chrome, Safari and Opera */
		&::-webkit-scrollbar {
			display: none;
		}
	}
	.agreements-list-item {
		min-height: 7.5rem;
		@include media('screen', 'md') {
			min-height: 5rem;
		}

		.with-ellipsis {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

.electronic-pdf-signatures {
	display:inline-block;
	height: 100%;
	width: 100%;
}

.signatory-list {
	display:flex;
	flex-direction: column;
	width: 100%;

	@include media('screen', 'md') {
		flex-direction: row;
		justify-content: start;
		width: auto;
	}

	.signatory-list-item {
		display:flex;
		flex-direction: row;
		flex-wrap: nowrap;

		.signatories-info{
			display: flex;
			flex-direction: row;
			@include media('screen', 'md') {
				flex-direction: column;
			}
		}
	}
}