.lists {

  .text-end {
    text-align: end;
  }

  .row-end {
    justify-content: end;
  }

  .font-bold {
    font-weight: 700 !important;
  }

  .text-primary {
    color: $secondary-light-color;
  }

  .list-header {
    display: block;
    color: $grey-700;
    font-size: rem(16);

    @include media('screen', 'md', '<lg') {
      font-size: rem(16);
    }

    .list-header-row {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
      min-height: 32px;
      
      > .row {
        width: 100%;
        padding: #{$default-padding / 2};
      }
    }

    .list-header-sort {
      display: none;

      @include media('screen', 'md', '<lg') {
        display: inline-block;
        padding-left: #{$default-padding / 2};
      }

      &::after {
        display: none;
      }

      .ordering {
        position: relative;
        top: 0;
        font-size: rem(10);

        &::before {
          content: $icon-list-ordering;
          font-family: 'advicefront';
          font-style: normal !important;
          // font-weight: map.get($font-weights, "bold") !important;
          font-weight: 700 !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }

      &.asc {
        .ordering {
          &::before {
            content: $icon-list-ordering;
          }
        }
      }
    }
  }

  .list-content {
    a {
      &.list-content-row {
        display: flex;
      }
    }

    .list-content-row {
      position: relative;
      min-height: 72px;

      > .row {
        padding: #{$default-padding / 2};
      }


      .row-max-width {
        align-items: stretch;

        > * {
          display: flex;
          align-items: center;
          min-height: 72px;
          padding-top: #{$default-padding / 2};
          padding-bottom: #{$default-padding / 2};
        }
      }

      @include media('screen', 'md', '<lg') {
        &:first-child {
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - #{($default-padding * 2)});
            height: 1px;
            background: $af-primary-light-color;
          }
        }
      }
    }
  }

  &.full-list {
    margin-left: -#{$default-margin * 2};
    margin-right: -#{$default-margin * 2};

    @include media('screen', 'md', '<lg') {
      padding-bottom: 0;
    }

    .list-header-row,
    .list-content-row {
      padding-left: #{$default-padding * 2};
      padding-right: #{$default-padding * 2};
    }
  }

  &.client-full-list {
    margin-left: -#{$default-margin};
    margin-right: -#{$default-margin};
    padding-bottom: #{$default-padding * 5};

    @include media('screen', 'md', '<lg') {
      margin-left: -#{$default-margin * 3};
      margin-right: -#{$default-margin * 3};
      padding-bottom: 0;
    }

    .list-content-row {
      cursor: pointer;

      &:active {
        box-shadow: inset 0px 0px 20px rgba($af-primary-light-color, 0.1), inset 0px 0px 5px rgba($af-terciary-dark-color, 0.8);
      }

      &:first-child {
        &::before {
          width: calc(100% - #{$default-padding});

          @include media('screen', 'md', '<lg') {
            width: calc(100% - #{($default-padding * 3) * 2});
            max-width: $container-max-width;
          }
        }
      }
    }
  }

  &.has-even-rows {
    .list-content {
      .list-content-row {
        &:nth-child(even) {
          background-color: $grey-100;
        }
        &:nth-child(odd) {
          background-color: transparent;
        }
      }
    }
  }

  &.has-odd-rows {
    .list-content {
      .list-content-row {
        &:nth-child(odd) {
          background-color: $grey-100;
        }
        &:nth-child(even) {
          background-color: transparent;
        }
      }
    }
  }

  &.has-small-rows {
    .list-content {
      .list-content-row {
        min-height: 48px;

        .row-max-width {
          min-height: 48px;

          > * {
            min-height: 48px;
            padding-top: #{$default-padding / 2};
            padding-bottom: #{$default-padding / 2};
          }
        }
      }
    }
  }

  &.has-medium-rows {
    .list-content {
      .list-content-row {
        min-height: 64px;

        .row-max-width {
          min-height: 64px;

          > * {
            min-height: 64px;
          }
        }
      }
    }
  }

  &.has-big-rows {
    .list-content {
      .list-content-row {
        min-height: 88px;

        .row-max-width {
          min-height: 88px;

          > * {
            min-height: 88px;
          }
        }
      }
    }
  }

  &.has-controls {
    .list-header {
      .list-header-row{
        @include media('screen', 'md', '<lg') {
          padding-right: #{$default-padding * 4};
        }
      }
    }

    .list-content {
      .list-content-row {
        padding-right: #{$default-padding * 3};

        @include media('screen', 'md', '<lg') {
          padding-right: #{$default-padding * 4};
        }

        &::before {
          display: none;

          @include media('screen', 'md', '<lg') {
            display: block;
            left: calc(50% - #{$default-padding * 1.5});
            width: calc(100% - #{$default-padding * 5});
          }
        }
      }
    }

    .row-controls {
      position: absolute;
      display: block;
      width: 24px;
      bottom: 24px;
      right: 24px;
      text-align: center;

      @include media('screen', 'md', '<lg') {
        right: 16px;
        // bottom: auto;
      }

      &::after {
        display: none;
      }
    }
  }

  &.has-no-divider {
    .list-content {
      .list-content-row {
        &:first-child {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .avatar {
    width: 32px;
    height: 32px;

    @include media('screen', 'md', '<lg') {
      width: 40px;
      height: 40px;
    }
  }

  /*
    This class is usefull when the content
    is clickable but it's not a link
  */
  .has-pointer {
    cursor: pointer;
  }

  // Overrides
  .form-section,
  .form-control {
    width: 100%;
  }

  /*
    This is used on the same
    class entry as the .row
  */
  .list-content-ellipsis {
    overflow: hidden;

    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .wrap-text {
    white-space: normal;
  }

}
