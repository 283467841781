.tooltip {
  max-width: 160px;
  margin: 8px;
  padding: 4px 8px;
  border-radius: 2px;
  background-color: $primary-dark-color;
  color: $white;
  font-size: rem(10);
  line-height: 1.5;
  text-align: left;
  z-index: 100;

  &[x-placement^="top"] {
    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      bottom: -5px;
    }
  }

  &[x-placement^="bottom"] {
    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      top: -5px;
    }
  }

  .tooltip-arrow {
    position: absolute;
    left: calc(50% - 5px);
    width: 0;
    height: 0;
    margin: 5px;
    margin-top: 0;
    margin-bottom: 0;
    border-color: $primary-dark-color;
    border-style: solid;
  }
}

// Data attribute on the triggered element
[data-tooltip] {
  cursor: pointer;
  transition: color .2s ease-in;
  color: $grey-700;
  vertical-align: 3px;

  &:hover {
    color: $secondary-light-color;
  }
}