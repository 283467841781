.tabs {
    &__header {
        display: flex;
        align-items: flex-end;

        &__button {
            position: relative;
            font: inherit;
            padding: 10px;
            cursor: pointer;
            display: inline-flex;
            justify-content: center;
            font-size: 1rem;
            font-weight: 700;
            padding: 8px;
            color: #34363a;

            & + & {
                margin-left: 8px;
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                bottom: -3px;
                height: 2px;
                width: 100%;
                background-color: c("primary-light");
                transform: scaleX(0);
                transition: transform 0.3s ease-out;
            }

            &.is-active::before {
                transform: scaleX(1);
            }
        }
    }

    &__panel {
        &:not(.is-active) {
            display: none;
        }
    }
}